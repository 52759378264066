import {
  faCalculator,
  faInfoCircle,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import adonis from "../assets/adonisresearch.svg";
import optima from "../assets/optimachemicalsupply.svg";
import logo from "../assets/real-time-peptide-calculator.svg";

const Calculator = () => {
  const [syringeType, setSyringeType] = useState("100");
  const [units, setUnits] = useState(100);
  const [tickMarks, setTickMarks] = useState(100);
  const [volume, setVolume] = useState(1);
  const [waterAmount, setWaterAmount] = useState(2);
  const [desiredDose, setDesiredDose] = useState(1500);
  const [peptideAmounts, setPeptideAmounts] = useState([{ id: 1, value: 10 }]);
  const [result, setResult] = useState({
    unitsDrawn: 0,
    totalDoses: 0,
    breakdown: "",
    fillWidth: 0,
  });
  const [savedProjects, setSavedProjects] = useState([]);
  const [unitType, setUnitType] = useState("mcg"); // State for unit type
  const [activeSection, setActiveSection] = useState("calculatorSection");

  useEffect(() => {
    loadSavedProjects();
    updateSyringeDetails(syringeType);
    document.addEventListener("contextmenu", (e) => e.preventDefault());
    document.addEventListener("keydown", preventShortcuts);
    return () => {
      document.removeEventListener("keydown", preventShortcuts);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateDosage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    units,
    tickMarks,
    volume,
    waterAmount,
    desiredDose,
    unitType,
    peptideAmounts,
  ]);

  const preventShortcuts = (e) => {
    if (
      (e.ctrlKey && e.shiftKey && ["I", "J", "C"].includes(e.key)) || // Ctrl+Shift+I/J/C
      (e.ctrlKey && e.key === "U") || // Ctrl+U (View source)
      e.key === "F12" // F12 key
    ) {
      e.preventDefault();
    }
  };

  const updateSyringeDetails = (type) => {
    if (type === "100") {
      setUnits(100);
      setTickMarks(100);
      setVolume(1);
    } else if (type === "40") {
      setUnits(40);
      setTickMarks(40);
      setVolume(1);
    } else {
      setUnits("");
      setTickMarks("");
      setVolume("");
    }
  };

  const handleSyringeTypeChange = (e) => {
    const value = e.target.value;
    setSyringeType(value);
    updateSyringeDetails(value);
  };

  const handleUnitChange = (e) => {
    const newUnitType = e.target.value;
    let convertedValue = parseFloat(desiredDose);

    if (newUnitType === "mg" && unitType === "mcg") {
      convertedValue = convertedValue / 1000;
    } else if (newUnitType === "mcg" && unitType === "mg") {
      convertedValue = convertedValue * 1000;
    }

    setUnitType(newUnitType);
    setDesiredDose(parseFloat(convertedValue.toFixed(3))); // Ensure it's a number
  };


  const handlePeptideChange = (id, value) => {
    setPeptideAmounts(
      peptideAmounts.map((peptide) =>
        peptide.id === id
          ? { ...peptide, value: parseFloat(value) || 0 }
          : peptide
      )
    );
  };

  const addPeptide = () => {
    const newId =
      peptideAmounts.length > 0
        ? peptideAmounts[peptideAmounts.length - 1].id + 1
        : 1;
    const updatedPeptides = [...peptideAmounts, { id: newId, value: 0 }];
    setPeptideAmounts(updatedPeptides);
  };

  const createSVGScale = (ticks = 50, maxUnits = 100, tickSpacing = 10) => {
    const lines = [];
    for (let i = 0; i <= ticks; i++) {
      const position = (i / ticks) * 100;
      lines.push(
        <line
          key={`line-${i}`}
          x1={`${position}%`}
          y1="0"
          x2={`${position}%`}
          y2={i % tickSpacing === 0 ? "15" : "8"}
          stroke="#000000ab"
        />
      );
      if (i % tickSpacing === 0 && i % (tickSpacing * 2) === 0) {
        // Add spacing logic
        lines.push(
          <text
            key={`text-${i}`}
            x={`${position}%`}
            y="25"
            fontSize="10"
            textAnchor={i === 0 ? "start" : i === ticks ? "end" : "middle"}
          >
            {(i * (maxUnits / ticks)).toFixed(0)}
          </text>
        );
      }
    }
    return (
      <svg width="100%" height="50">
        {lines}
      </svg>
    );
  };


  const calculateDosage = () => {
    const totalPeptideAmount = peptideAmounts.reduce(
      (sum, peptide) => sum + peptide.value,
      0
    );

    if (
      !units ||
      !volume ||
      !waterAmount ||
      !totalPeptideAmount ||
      !desiredDose ||
      units <= 0 ||
      volume <= 0 ||
      waterAmount <= 0 ||
      totalPeptideAmount <= 0 ||
      desiredDose <= 0
    ) {
      setResult({
        unitsDrawn: 0,
        totalDoses: 0,
        breakdown: "Please enter valid positive numbers for all fields.",
        fillWidth: 0,
      });
      return;
    }

    const doseInMg = unitType === "mcg" ? desiredDose / 1000 : desiredDose;

    if (doseInMg > totalPeptideAmount) {
      setResult({
        unitsDrawn: 0,
        totalDoses: 0,
        breakdown: "Desired dose exceeds available peptide amount.",
        fillWidth: 0,
      });
      return;
    }

    const concentration = totalPeptideAmount / waterAmount;
    const volumeToDraw = doseInMg / concentration;

    if (volumeToDraw > volume) {
      setResult({
        unitsDrawn: 0,
        totalDoses: 0,
        breakdown: "Volume to draw exceeds syringe capacity.",
        fillWidth: 0,
      });
      return;
    }

    const unitsToDraw = (volumeToDraw / volume) * units;
    const totalDoses = totalPeptideAmount / doseInMg;

    setResult({
      unitsDrawn: Math.round(unitsToDraw),
      totalDoses: parseFloat(totalDoses.toFixed(2)),
      breakdown: `${Math.floor(
        unitsToDraw / units
      )} full syringe(s) + ${Math.round(
        unitsToDraw % units
      )} unit(s) for a dose of ${doseInMg} mg`,
      fillWidth: parseFloat(((unitsToDraw / units) * 100).toFixed(1)),
    });

  };


  const saveProject = () => {
    const project = {
      syringeType,
      units,
      tickMarks,
      volume,
      waterAmount,
      desiredDose,
      peptideAmounts: peptideAmounts.map((p) => p.value),
      resultInfo: result,
    };
    const updatedProjects = [...savedProjects, project];
    setSavedProjects(updatedProjects);
    localStorage.setItem("savedProjects", JSON.stringify(updatedProjects));
    alert("Project saved successfully!");
  };

  const loadSavedProjects = () => {
    const projects = JSON.parse(localStorage.getItem("savedProjects")) || [];
    setSavedProjects(projects);
  };

  const removeProject = (index) => {
    const updatedProjects = savedProjects.filter((_, i) => i !== index);
    setSavedProjects(updatedProjects);
    localStorage.setItem("savedProjects", JSON.stringify(updatedProjects));
  };

  const showSection = (sectionId) => {
    setActiveSection(sectionId);
  };

  return (
    <div>
      <div>
        <Link to="/" onClick={() => setActiveSection("calculatorSection")}>
          <img src={logo} alt="Real-Time Peptide Calculator" className="logo" />
        </Link>
      </div>
      <div
        id="calculatorSection"
        className={activeSection === "calculatorSection" ? "" : "hidden"}
      >
        <h3>Syringe Details</h3>
        <div className="form-section">
          <div className="form-group">
            <label htmlFor="syringeType">Syringe Type:</label>
            <select
              id="syringeType"
              value={syringeType}
              onChange={handleSyringeTypeChange}
            >
              <option value="100">U-100</option>
              <option value="40">U-40</option>
              <option value="custom">Other (manual entry)</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="unitsInput">Units in Syringe:</label>
            <input
              type="number"
              id="unitsInput"
              value={units}
              onChange={(e) => setUnits(parseFloat(e.target.value))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="tickMarks">Tick Marks:</label>
            <input
              type="number"
              id="tickMarks"
              value={tickMarks}
              onChange={(e) => setTickMarks(parseFloat(e.target.value))}
            />
          </div>
          <div className="form-group">
            <label htmlFor="volume">Volume (mL):</label>
            <input
              type="number"
              id="volume"
              step="0.01"
              value={volume}
              onChange={(e) => setVolume(parseFloat(e.target.value))}
            />
          </div>
        </div>

        <h3>Vial Details</h3>
        <div className="form-section" id="vialDetails">
          <div className="form-group">
            <label htmlFor="waterAmount">Water (mL):</label>
            <input
              type="number"
              id="waterAmount"
              value={waterAmount}
              step="0.01"
              onChange={(e) => setWaterAmount(parseFloat(e.target.value))}
            />
          </div>
          {peptideAmounts.map((peptide) => (
            <div className="form-group" key={peptide.id}>
              <label htmlFor={`peptide-${peptide.id}`}>Peptide (mg):</label>
              <input
                type="number"
                className="peptide-input"
                value={peptide.value}
                step="0.01"
                onChange={(e) =>
                  handlePeptideChange(peptide.id, e.target.value)
                }
              />
            </div>
          ))}
          <div className="add-peptide-container">
            <button className="add-peptide-btn" onClick={addPeptide}>
              +
            </button>
          </div>
        </div>

        <h3>Amount Desired</h3>
        <div className="form-group">
          <label htmlFor="desiredDose">Peptide Amount:</label>
          <div className="amount-desired-group">
            <input
              type="number"
              id="desiredDose"
              className="input-field"
              value={desiredDose}
              step="0.01"
              onChange={(e) => setDesiredDose(parseFloat(e.target.value))}
            />
            <select
              id="unitSelect"
              className="unit-dropdown"
              value={unitType}
              onChange={handleUnitChange}
            >
              <option value="mcg">mcg</option>
              <option value="mg">mg</option>
            </select>
          </div>
        </div>

        <div className="scale-container">
          <div
            className="fill-bar"
            style={{ width: `${result.fillWidth}%` }}
          ></div>
          <div id="scaleComponent" className="svg-scale">
            {createSVGScale(units, units, units / 10)}
          </div>
        </div>

        <div className="info" id="result">
          Draw: <span id="unitsDrawn">{result.unitsDrawn}</span> Units
          <br />
          Vial contains <span id="doses">{result.totalDoses}</span> doses
          <br />
          Breakdown: <span id="breakdown">{result.breakdown}</span>
        </div>
        <button
          onClick={saveProject}
          style={{
            marginTop: "20px",
            padding: "20px",
            backgroundColor: "#101a00",
            color: "#ffffff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Save Project
        </button>
      </div>

      <div
        id="savedProjectsSection"
        className={activeSection === "savedProjectsSection" ? "" : "hidden"}
      >
        <h3>Saved Projects</h3>
        <div id="savedProjects">
          {savedProjects.map((project, index) => (
            <div className="info" key={index}>
              <h3>Project {index + 1}</h3>
              <p>Syringe Type: {project.syringeType}</p>
              <p>Units: {project.units}</p>
              <p>Tick Marks: {project.tickMarks}</p>
              <p>Volume: {project.volume}</p>
              <p>Water Amount: {project.waterAmount}</p>
              <p>Desired Dose: {project.desiredDose}</p>
              <p>Peptide Amounts: {project.peptideAmounts.join(", ")}</p>
              <div className="scale-container">
                <div
                  className="fill-bar"
                  style={{
                    width: `${project.resultInfo.fillWidth}%`,
                    height: "100%",
                    backgroundColor: "#B9F400",
                  }}
                ></div>
                <div id={`savedScaleComponent${index}`} className="svg-scale">
                  {createSVGScale(
                    units, // Use project-specific data if needed
                    units, // Ensure this is valid within the project
                    units / 10 // Adjust tick spacing as necessary
                  )}
                </div>
              </div>
              <div className="info" id={`savedResult${index}`}>
                Draw: {project.resultInfo.unitsDrawn} Units
                <br />
                Vial contains {project.resultInfo.totalDoses} doses
                <br />
                Breakdown: {project.resultInfo.breakdown}
              </div>
              <button
                className="remove-btn"
                onClick={() => removeProject(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* About Section */}
      <div
        id="infoSection"
        className={activeSection === "infoSection" ? "" : "hidden"}
      >
        <h3>About Real-Time Peptide Calculator</h3>
        <p>
          Real-Time Peptide Calculator is a user-friendly app designed for
          accurate peptide dosage calculations. Enter your data and let the app
          provide precise results, simplifying the process for peptide
          administration and research purposes.
        </p>
        <p>
          <strong>Important Note:</strong>
          <br />
          This app is intended for calculation purposes only and does not
          provide medical advice. Always consult a qualified healthcare
          professional before making any medical decisions.
        </p>
        <h4>Sponsored By:</h4>
        <p>
          Proudly sponsored by Adonis Research Peptides and Optima Supply
          Peptides. Visit their websites for premium peptide products:
        </p>
        <div className="brand-banner">
          <a
            href="https://www.adonisresearch.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={adonis}
              alt="Adonis Research Peptides"
              className="brand-logo"
            />
          </a>
          <a
            href="https://www.optimasupply.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={optima}
              alt="Optima Supply Peptides"
              className="brand-logo"
            />
          </a>
        </div>
        <h4>Helpful Links:</h4>
        <ul>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms-conditions">Terms and Conditions</a>
          </li>
        </ul>
        <p>
          <strong>Disclaimer:</strong>
          <br />
          Always consult a healthcare provider for medical guidance. This app is
          not a replacement for professional medical advice.
        </p>
      </div>

      {/* Footer */}
      <footer>
        <p className="small-banner">
          &copy; {new Date().getFullYear()} Real-Time Peptide Calculator |
          Sponsored by{" "}
          <a
            href="https://adonisresearch.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Adonis Research Peptides
          </a>{" "}
          &{" "}
          <a
            href="https://optimasupply.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Optima Supply Peptides
          </a>
        </p>
      </footer>

      <div className="floating-menu">
        <button onClick={() => showSection("calculatorSection")}>
          <FontAwesomeIcon icon={faCalculator} />
        </button>
        <button onClick={() => showSection("savedProjectsSection")}>
          <FontAwesomeIcon icon={faSave} />
        </button>
        <button onClick={() => showSection("infoSection")}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </button>
      </div>
    </div>
  );
};

export default Calculator;
