import React, { useEffect, useState } from "react";
import Calculator from "./components/Calculator";
import "./styles/App.css";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  useEffect(() => {
    // Listen for the `beforeinstallprompt` event
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault(); // Prevent the default install prompt
      setDeferredPrompt(e); // Save the event for later
      setShowInstallButton(true); // Show the install button
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", () => {});
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Reset the prompt
        setShowInstallButton(false); // Hide the install button
      });
    }
  };

  return (
    <div className="App">
      <Calculator />
      {showInstallButton && (
        <button className="install-button" onClick={handleInstallClick}>
          Install App
        </button>
      )}
    </div>
  );
}

export default App;
